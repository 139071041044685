/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'triangle-half': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.065 2.016A.13.13 0 008.002 2v11.983l6.856.017a.12.12 0 00.066-.017.2.2 0 00.054-.06.18.18 0 00-.002-.183L8.12 2.073a.15.15 0 00-.054-.057zm-1.043-.45a1.13 1.13 0 011.96 0l6.856 11.667c.458.778-.091 1.767-.98 1.767H1.146c-.889 0-1.437-.99-.98-1.767z"/>',
    },
});
